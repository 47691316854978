import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "subbody modulewrap"
};
const _hoisted_2 = {
  key: 0,
  class: "schoolwrap"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "schoolcity"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "schooltitle"
};
const _hoisted_7 = {
  class: "schoolinfo"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "schooltitle"
};
const _hoisted_10 = {
  class: "subcontitle"
};
const _hoisted_11 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'school',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitle
    } = FFmixin();
    const route = useRoute();
    const router = useRouter();
    const FFdata = reactive({
      infodata: [],
      infoDetail: {}
    });
    const getData = async () => {
      setTitle(2, 0, 220, () => {
        let id = route.params.id;
        if (id) {
          proxy.$common.fetch({
            'act': 'telfort_schooldetail',
            'exts': 'id=' + id,
            msg: ''
          }, res => {
            FFdata.infoDetail = res.infodata;
          });
        } else {
          proxy.$common.fetch({
            'act': 'telfort_school',
            msg: ''
          }, res => {
            FFdata.infodata = res.infodata;
          });
        }
      });
    };
    const goUrl = id => {
      let url = '/about/school/' + id;
      router.push(url);
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$common.basic.isArrayExist(_unref(FFdata).infodata) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infodata, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "schoollist",
          key: item.id,
          onClick: $event => goUrl(item.id)
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
          src: item.imgcity
        }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$common.langInfo(item.city, item.cityen)), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
          src: item.imgschool
        }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$common.langInfo(item.title, item.title_en)), 1)])], 8, _hoisted_3);
      }), 128))])) : _createCommentVNode("", true), _ctx.$common.basic.isObjExist(_unref(FFdata).infoDetail) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$common.langInfo(_unref(FFdata).infoDetail.title, _unref(FFdata).infoDetail.title_en)), 1), _createElementVNode("div", {
        class: "subcon editor",
        innerHTML: _ctx.$common.langInfo(_unref(FFdata).infoDetail.concn, _unref(FFdata).infoDetail.conen)
      }, null, 8, _hoisted_11)], 64)) : _createCommentVNode("", true)]);
    };
  }
};